// src/ProductDetails.js
import React, { useState, useEffect } from 'react';
import { BsBookmark, BsBookmarkFill, BsGem, BsCircleFill, BsChevronRight, BsChevronDown } from 'react-icons/bs';
import { ProductColors } from '../ProductColors';

import '../../style/ProductDetails.scss';

import { useTranslation } from 'react-i18next';

const ProductDetails = ({ product, setShowMiniCart, handleFavoriteQuantityChange, favorite }) => {
    const { t } = useTranslation();
    const [isFavorited, setIsFavorited] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleChange = (e) => {
        const value = Math.max(1, parseInt(e.target.value) || 1);
        setQuantity(value);
    };

    const handleFavoriteClick = (e, productId, isFavorite) => {
        e.stopPropagation();
        setIsFavorited((prevFavorites) => {
            const updatedFavorites = { ...prevFavorites, [productId]: !isFavorite };

            handleFavoriteQuantityChange(productId, isFavorite ? 'decrease' : 'increase');

            return updatedFavorites;
        });
    };

    useEffect(() => {
        if (favorite && favorite.products && Array.isArray(favorite.products)) {
            const favoriteIds = favorite.products.reduce((acc, item) => {
                acc[item.id] = true;
                return acc;
            }, {});
            setIsFavorited(favoriteIds);
        }
    }, [favorite]);

    const colorPropertiesId = product.properties
        .filter(prop => prop.type === 'color')
        .map(prop => prop.propertyId);

    const materialValue = product.properties
        .filter(prop => prop.type === 'material')
        .map(prop => prop.value);

    const sizeValue = product.properties
        .filter(prop => prop.type === 'size')
        .map(prop => prop.value);

    const handleProductClick = (productUri) => {
        window.location.pathname = productUri;
    };

    const handleAddToCartClick = () => {
        shopSessionCartAddProduct(product.id, 1);
        setTimeout(function () {
            setShowMiniCart(true);
        }, 300);
    };

    return (
        <div className="product-details">
            {product &&
                <>
                    <p className="product-material">{materialValue}</p>
                    <div className="title-price-favorite-row">
                        <h2 className="product-title">{product.name}</h2>
                        <p className="product-price">{product.price}</p>
                        <span className="icon-bookmark" onClick={(e) => handleFavoriteClick(e, product.id, isFavorited[product.id])}>
                            {isFavorited[product.id] ? <BsBookmarkFill className="favorite-icon filled" /> : <BsBookmark className="favorite-icon" />}
                        </span>
                    </div>
                    <div>
                        {product.stockStatus === "IN_STOCK" && (
                            <>
                                <div className='product-in-stock-container'>
                                    <BsCircleFill className="green-in-stock-icon" />
                                    <p className='in-stock-text'>{t('pim-stockStatusInStock')}</p>
                                </div>
                            </>
                        )}
                        {product.stockStatus === "INCOMING" && (
                            <>
                                <div className='product-in-stock-container'>
                                    <BsCircleFill className="yellow-in-stock-icon" />
                                    <p className='in-stock-text'>{t('pim-stockStatusIncomming')}</p>
                                </div>
                                <div className="collapsible-text">
                                    {!isExpanded && (
                                        <div className="collapsed-view" onClick={() => setIsExpanded(true)}>
                                            <span>{t('shop-IncomingText').split(" ").slice(0, 3).join(" ") + "..."}</span>
                                            <BsChevronRight className="arrow-icon" />
                                        </div>
                                    )}
                                    {isExpanded && (
                                        <div className="expanded-view">
                                            <div className="expanded-header">
                                                <p style={{ margin: 0 }}>{t('shop-IncomingText')}</p>
                                                <button className="toggle-button" onClick={() => setIsExpanded(false)}>
                                                    <BsChevronDown className="arrow-icon" />
                                                </button>
                                            </div>
                                            <p>{t('shop-IncomingContact')}</p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {product.stockStatus === "SOLD_OUT" || product.stockStatus === "TEMPORARY_OUT_OF_STOCK" && (
                            <>
                                <div className='product-in-stock-container'>
                                    <BsCircleFill className="red-in-stock-icon" />
                                    <p className='in-stock-text'>{t('pim-stockStatusOutOfStock')}</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="color-section">
                        <p className="product-color">{t('shop-Colour')}</p>
                        <div className="color-container">
                            {/* Displaying color circles based on propertyId */}
                            {colorPropertiesId.map((propertyId, index) => {
                                const color = ProductColors[propertyId];

                                if (color) {
                                    // If it's an array (multiple colors), render each color as a split circle
                                    if (Array.isArray(color)) {
                                        return (
                                            <div
                                                key={index}
                                                className="color-circle-split"
                                                style={{
                                                    background: `linear-gradient(to right, ${color[0]} 50%, ${color[1]} 50%)`
                                                }}
                                                aria-label={`Color: ${color[0]} / ${color[1]}`}
                                            />
                                        );
                                    }
                                    // If it's a single color, render just that one color
                                    return (
                                        <div
                                            key={index}
                                            className="color-circle"
                                            style={{ backgroundColor: color }}
                                            aria-label={`Color: ${color}`}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    <div className="size-section">
                        {(product.sizes.length > 0 || sizeValue.length > 0) && (
                            <>
                                <p className="size-title">{t('pim-size')}</p>
                                <div className="size-container">
                                    {product.sizes.length > 0 ? (
                                        product.sizes.map((prop, index) => (
                                            <div
                                                key={index}
                                                style={{ cursor: "pointer" }}
                                                className={product.id === prop.itemId ? "selected" : "size-box"}
                                                onClick={() => handleProductClick(prop.uri)}
                                            >
                                                {prop.value}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="size-box">{sizeValue}</div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="button-container">
                        <input
                            type="number"
                            className="quantity-input"
                            value={quantity}
                            onChange={handleChange}
                            min="1"
                        />
                        <button
                            className="add-to-cart-button"
                            onClick={handleAddToCartClick}
                        >
                            {t('shop-addToCart')}
                            <BsGem className="gem-icon" />
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default ProductDetails;
